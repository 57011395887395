import React, { useState } from 'react';
import { TextField } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Login.css';
import Logo from '../../logo.png';

import i18n from '../../i18n';
import { useTranslation } from "react-i18next";

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import InputAdornment from '@mui/material/InputAdornment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

function setToken(userToken) {
  LocalStorageTTL.setWithExpiry('token', userToken);
}
function getToken() {
  const userToken = LocalStorageTTL.getWithExpiry('token');
  return userToken
}

async function forgetPassword(userEmail) {
  try
  {
     axios.post('', {
      "name":"forgetPassword",
      "param": {
          "email":userEmail
      }
    })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          //--SUCCESS
          alert(response.data.message);
          window.location.href='/home/login';
        }else{
          alert(response.data.message);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"連接出現問題！請聯絡相關技術人員！":"连接出现问题！请联络相关技术人员！");
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }
  catch (error)
  {
    alert(error);
  }
};

export default function ForgetPassword (props) {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const forgetPasswordSubmit = async e => {
    e.preventDefault();
    forgetPassword(userEmail);
  };
  return(
    <div className="app-wrapper">
      <div className="app-page ">
        <Container>
          <form onSubmit={forgetPasswordSubmit}>
            <Row>
              <div className="app-logo">
                <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
                <label className="app-logo-text">旺角廣東道1094-1096號快富閣1樓全層</label>
                <label className="app-logo-text">1/F, Fife Court, 1094-1096 Canton Road, Mongkok, HongKong</label>
                <label className="app-logo-text"><a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp: 96171444</a></label>
              </div>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="login-container-text">
                  <p>忘記密碼</p>
                  <p>Forgot Password</p>
                </div>
                <div className="">
                  <div>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="電子郵件 Email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          type="email"
                          color="warning"
                          focused
                          fullwidth
                          value={userEmail}
                          onChange={e => setUserEmail(e.target.value.trim())}
                          onKeyDown={e => ( e.key === " " )? e.preventDefault():""}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="login-page-bottom">
                  <div className="login-bottom">
                    <div className="login-bottom-container">
                      <button type="submit" className="login-bottom-container-submit">確認 Confirm</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </div>
  )
};
