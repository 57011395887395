import React, { useState , useRef, createRef} from 'react';
import { Dimensions } from "react-native";
import { useParams, useNavigate } from "react-router-dom";
import { TextField } from '@mui/material';
import { Box,Paper, stackLayout,List, Grid } from '@mui/material';

import dayjs from 'dayjs';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";

import './BookingTable.css';
import Logo from '../../logo.png';
import TokenIcon from '../../img/icon-DancingTicket.png';

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'

export default function BookingTable() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const [classes, setClasses] = useState();
  //--Clear
  localStorage.removeItem('proceed_link');
  localStorage.removeItem('member_only');
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getAllLessons',
          headers: {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getAllLessons");
          console.log(response.data.classes);
          console.log("-------------");
          setClasses(response.data.classes);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(classes){
    return(
      <div className="app-wrapper">
        <div className="app-page">
          <Container>
            <Row>
              <div className="app-logo">
                <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
                <label className="app-logo-text">旺角廣東道1094-1096號快富閣1樓全層</label>
                <label className="app-logo-text">1/F, Fife Court, 1094-1096 Canton Road, Mongkok, HongKong</label>
                <label className="app-logo-text"><a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp: 96171444</a></label>
              </div>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="app-booking-page-main">
                  <BookingTableBlock classes={classes} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
function BookingTableBlock(props){
  var isToday = require('dayjs/plugin/isToday')
  dayjs.extend(isToday)
  const navigate = useNavigate();
  //--set calendar ref
  const calendarRef = useRef(null);
  //--get screen size
  const window = Dimensions.get("window");
  const screen = Dimensions.get("screen");

  const onChange = ({ window, screen }) => {
    // setDimensions({ window, screen });
    if(window.width>992){
      // console.log(calendarRef.current.getApi());
      calendarRef.current.getApi().changeView('timeGridWeek');
    }else{
      // console.log(calendarRef.current.getApi());
      calendarRef.current.getApi().changeView('timeGridThreeDay');
    }
  };
  React.useEffect(() => {
    Dimensions.addEventListener("change", onChange);
  });
  const { t } = useTranslation();
  const token = TokenService.getToken();
  const eventOnClick = (eventInfo, action) => {
    console.log(eventInfo);
    //--
    if(action == "details"){
      LocalStorageTTL.setWithExpiry('proceed_bookTable_initialDate', eventInfo.event.startStr.substring(0,10));
      window.location.href=navigate(('/book/'+eventInfo.event.id)+'/'+eventInfo.event.startStr.substring(0,10)+"/details");
    }else if (action == "payment") {
      if(!token){
        LocalStorageTTL.setWithExpiry('proceed_link', ('/home/book/'+eventInfo.event.id)+"/"+eventInfo.event.startStr.substring(0,10)+'/payment');
      }else{
        localStorage.removeItem('proceed_link');
      }
      LocalStorageTTL.setWithExpiry('proceed_bookTable_initialDate', eventInfo.event.startStr.substring(0,10));
      window.location.href=navigate(('/book/'+eventInfo.event.id)+'/'+eventInfo.event.startStr.substring(0,10)+'/payment');
    }
  }
  function countBookedNum(bookings,current_date){
    let count = 0;
    bookings.forEach(function (booking) {
      if(booking.status!="cancel"){
        if(booking.class_date.substring(0,10)==current_date.substring(0,10)){
          count++
        }
      }
    });
    return count
  }
  const renderEventContent = (eventInfo) => {
    console.log(eventInfo);
    const remaining = eventInfo.event.extendedProps.capacity - countBookedNum(eventInfo.event.extendedProps.bookings, eventInfo.event.startStr);
    return (
             <div className="booking-event">
               <div><i>{eventInfo.timeText}</i></div>
               <hr className="bold-horizen-line"/>
               <div><i>{eventInfo.event.title}</i></div>
               <div><i>{eventInfo.event.extendedProps.tutorName}</i></div>
               {
                 dayjs().isBefore(dayjs(eventInfo.event.startStr.substring(0,10)+" "+eventInfo.event.extendedProps.end_time.substring(0,5)))?
                  <>
                  <div><i>{remaining<1?"人數已滿":"剩"+remaining+"位"}</i></div>
                  <button type="submit" className="btn-booking-event btn-booking-event-details" onClick={() => eventOnClick(eventInfo,"details")}>詳情</button>
                  <button type="submit" className="btn-booking-event btn-booking-event-booking" onClick={() => eventOnClick(eventInfo,"payment")} disabled={remaining<1?"disabled":""}>預約</button>
                  </>
                  :
                  <>
                  <button type="submit" className="btn-booking-event btn-booking-event-details" onClick={() => eventOnClick(eventInfo,"details")}>詳情</button>
                  </>
               }
             </div>
    )
  };
  const initialDate = LocalStorageTTL.getWithExpiry('proceed_bookTable_initialDate')==""?dayjs().format('YYYY-MM-DD'):LocalStorageTTL.getWithExpiry('proceed_bookTable_initialDate');
  if(props){
    console.log(props);
    if(props.classes !== undefined){
      return(
        <FullCalendar
          ref={calendarRef}
          plugins={[ timeGridPlugin ]}
          initialDate={initialDate}
          initialView={(window.width>992)?"timeGridWeek":"timeGridThreeDay"}
          views= {{
            timeGridThreeDay: {
              type: 'timeGrid',
              duration: { days: 3 }
            }
          }}
          allDayText="全日"
          titleFormat={{year: 'numeric', month: 'numeric', day: 'numeric'}}
          scrollTime="10:00:00"
          eventTimeFormat= {{ // like '14:30:00'
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false
          }}
          weekends={true}
          customButtons={{
            titleButton: {
              text: "Offline mode",
            },
          }}
          headerToolbar={{
            start: 'prev,next', // will normally be on the left. if RTL, will be on the right
            center: 'title',
            end: 'today', // will normally be on the right. if RTL, will be on the left
          }}
          buttonText={{
            today:    '今天 Today',
            prev: '上一個 Previous',
            next: '下一個 Next'
          }}
          events={props.classes}
          eventContent={renderEventContent}
        />
      );
    }else{
      return(<h3></h3>);
    }
  }else{
    return(<h3></h3>);
  }
}
