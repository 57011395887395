import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import './BookingPayment.css';
import Logo from '../../logo.png';
import CreditCardImg from '../../img/CreditCardImg.png';
import FpsImg from '../../img/FpsImg.png';
import PaymeImg from '../../img/PaymeImg.png';
import TokenImg from '../../img/TokenImg.png';
import TokenIcon from '../../img/icon-DancingTicket.png';


export default function BookingPayment() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const token = TokenService.getToken();
  const [lesson, setLesson] = useState();
  const { class_id, date } = useParams();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  //--popup modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = async e => {
    e.preventDefault();
    await bookByToken(lesson.cid, (date+" "+lesson.start_time), lesson.token);
  };
  async function bookByToken(class_id ,class_date, token) {
      try
      {
         axios.post('', {
          "name":"bookByToken",
          "param": {
              "class_id":class_id,
              "class_date":class_date,
              "token":token,
          },
          "headers": {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        })
        .then((response) => {
          console.log(response);
          if(response.status == 200){
            console.log(response.data);
            if(response.data.status == 200){
              LocalStorageTTL.setWithExpiry('booking_detail', JSON.stringify(response.data.user.booking_detail));
              window.location.replace("/home/book/"+response.data.user.booking_detail.id+"/success");
            } else {
              alert(response.data.message);
            }
          }else{
            alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
      catch (error)
      {
        alert(error);
      }
  };
  function countBookedNum(bookings,current_date){
    let count = 0;
    bookings.forEach(function (booking) {
      if(booking.status!="cancel"){
        if(booking.class_date.substring(0,10)==current_date){
          count++
        }
      }
    });
    return count
  }
  //--
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getLessonById',
          cid: class_id,
          headers: {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getLessonById");
          console.log(response.data.class);
          console.log("-------------");
          setLesson(response.data.class);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(lesson){
    console.log("getSessionValue");
    const remaining = lesson.capacity - countBookedNum(lesson.bookings, date);
    //--Set day index
    var display_day = "Sun";
    if(lesson.day_index == 1){
      display_day = "Mon";
    }else if (lesson.day_index == 2) {
      display_day = "Tue";
    }else if (lesson.day_index == 3) {
      display_day = "Wed";
    }else if (lesson.day_index == 4) {
      display_day = "Thu";
    }else if (lesson.day_index == 5) {
      display_day = "Fri";
    }else if (lesson.day_index == 6) {
      display_day = "Sat";
    }
    if(token&&token!="visitor_token"){
      return(
        <div className="app-wrapper">
          <div className="app-page ">
            <Container>
              <Row>
                <div className="app-logo">
                  <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
                  <label className="app-logo-text">旺角廣東道1094-1096號快富閣1樓全層</label>
                  <label className="app-logo-text">1/F, Fife Court, 1094-1096 Canton Road, Mongkok, HongKong</label>
                  <label className="app-logo-text"><a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp: 96171444</a></label>
                </div>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={4}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className="app-page-topic">
                    <p>付款方法</p>
                    <p>Payment Method</p>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <button
                        type="submit"
                        className="app-payment-container-button app-payment-container-visa"
                        onClick={event =>  window.location.href='payment/card'}
                      >
                        <img className="" src={CreditCardImg}/>
                      </button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <button
                        type="submit"
                        className="app-payment-container-button app-payment-container-fps"
                        onClick={event =>  window.location.href='payment/fps'}
                      >
                        <img className="" src={FpsImg}/>
                      </button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <button
                        type="submit"
                        className="app-payment-container-button app-payment-container-payme"
                        onClick={event =>  window.location.href='payment/payme'}
                      >
                        <img className="" src={PaymeImg}/>
                      </button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <button
                        type="submit"
                        className="app-payment-container-button app-payment-container-token"
                        onClick={handleOpen}
                      >
                        <img className="" src={TokenImg}/>
                      </button>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                          backdrop: {
                            timeout: 500,
                          },
                        }}
                      >
                        <Fade in={open}>
                          <Box sx={style}>
                            <form onSubmit={handleSubmit}>
                              <Typography id="transition-modal-title" variant="h6" component="h2">
                                使用舞卷 Use Token
                              </Typography>
                              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                確認使用 <img className="img-tokenIcon" src={TokenIcon}/> ?
                              </Typography>
                              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                                Confirm to use <img className="img-tokenIcon" src={TokenIcon}/>?
                              </Typography>
                              <br/>
                              <button type="button" className="modal-bottom-button white-button" onClick={handleClose}>取消 Cancel</button>
                              <br/>
                              <button type="submit" className="modal-bottom-button black-button">確認 Confirm</button>
                            </form>
                          </Box>
                        </Fade>
                      </Modal>
                    </Grid>
                  </Grid>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Card
                    style={{
                      width: '100%',
                      borderRadius: '28px',
                      backgroundColor: '#E6EDF3',
                      marginTop:'16px'
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                        }}
                        style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                        variant="body1"
                      >
                        <div className="app-payment-details-header">
                          {date} [{display_day}]
                        </div>
                        <div className="app-payment-details-header">
                          {lesson.start_time.substring(0,5)} - {lesson.end_time.substring(0,5)}
                        </div>
                        <hr/>
                        <div className="app-payment-details-content">
                          {lesson.class_details}
                        </div>
                        <div className="app-payment-details-content">
                          {lesson.room_name}
                        </div>
                        <div className="app-payment-details-content">
                          {remaining<1?"人數已滿":"剩"+remaining+"位"}
                        </div>
                        <div className="app-payment-details-footer">
                          HKD${(lesson.token*150)} OR <img className="img-tokenIcon" src={TokenIcon}/> 舞卷*{lesson.token}
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                  <div className="app-bottom-container-booking">
                    <div className="app-bottom-container-booking-text">*場地密碼會於確認付款後提供。</div>
                    <div className="app-bottom-container-booking-text">*Venue password will be given after confirmation.</div>
                    <div className="app-bottom-container-booking-text">*一但確認預約，將不可進行更改。</div>
                    <div className="app-bottom-container-booking-text">*Once confirm, it cannot be undo.</div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }else{
      return(
        <div className="app-wrapper">
          <div className="app-page ">
            <Container>
              <Row>
                <div className="app-logo">
                  <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
                  <label className="app-logo-text">旺角廣東道1094-1096號快富閣1樓全層</label>
                  <label className="app-logo-text">1/F, Fife Court, 1094-1096 Canton Road, Mongkok, HongKong</label>
                  <label className="app-logo-text"><a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp: 96171444</a></label>
                </div>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={4}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                  <div className="app-page-topic">
                    <p>付款方法</p>
                    <p>Payment Method</p>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <button
                        type="submit"
                        className="app-payment-container-button app-payment-container-visa"
                        onClick={event =>  window.location.href='payment/card'}
                      >
                        <img className="" src={CreditCardImg}/>
                      </button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <button
                        type="submit"
                        className="app-payment-container-button app-payment-container-fps"
                        onClick={event =>  window.location.href='payment/fps'}
                      >
                        <img className="" src={FpsImg}/>
                      </button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>
                      <button
                        type="submit"
                        className="app-payment-container-button app-payment-container-payme"
                        onClick={event =>  window.location.href='payment/payme'}
                      >
                        <img className="" src={PaymeImg}/>
                      </button>
                    </Grid>
                  </Grid>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Card
                    style={{
                      width: '100%',
                      borderRadius: '28px',
                      backgroundColor: '#E6EDF3',
                      marginTop:'16px'
                    }}
                  >
                    <CardContent>
                      <Typography
                        sx={{
                          display: '-webkit-box',
                          overflow: 'hidden',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 1,
                        }}
                        style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                        variant="body1"
                      >
                        <div className="app-payment-details-header">
                          {date} [{display_day}]
                        </div>
                        <div className="app-payment-details-header">
                          {lesson.start_time.substring(0,5)} - {lesson.end_time.substring(0,5)}
                        </div>
                        <hr/>
                        <div className="app-payment-details-content">
                          {lesson.class_details}
                        </div>
                        <div className="app-payment-details-content">
                          {lesson.room_name}
                        </div>
                        <div className="app-payment-details-content">
                          {remaining<1?"人數已滿":"剩"+remaining+"位"}
                        </div>
                        <div className="app-payment-details-footer">
                          HKD${(lesson.token*150)} OR <img className="img-tokenIcon" src={TokenIcon}/> 舞卷*{lesson.token}
                        </div>
                      </Typography>
                    </CardContent>
                  </Card>
                  <div className="app-bottom-container-booking">
                    <div className="app-bottom-container-booking-text">*場地密碼會於確認付款後提供。</div>
                    <div className="app-bottom-container-booking-text">*Venue password will be given after confirmation.</div>
                    <div className="app-bottom-container-booking-text">*一但確認預約，將不可進行更改。</div>
                    <div className="app-bottom-container-booking-text">*Once confirm, it cannot be undo.</div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2}>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }else{
    return(<h3></h3>);
  }
};
