import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import Logo from '../../logo.png';
import CreditCardImg from '../../img/CreditCardImg.png';
import FpsImg from '../../img/FpsImg.png';
import PaymeImg from '../../img/PaymeImg.png';
import TokenImg from '../../img/TokenImg.png';
import TokenIcon from '../../img/icon-DancingTicket.png';


export default function ProfileTokenPackage() {
  const { t } = useTranslation();
  const [packages, setPackages] = useState();
  const styles = StyleSheet.create({
    container: {
      height: '500px'
    },
    scrollView: {
      margin: '20px',
    }
  });
  //--
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getAllPackages',
          headers: {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getAllPackages");
          console.log(response.data.packages);
          console.log("-------------");
          setPackages(response.data.packages);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(packages){
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Row>
              <div className="app-logo">
                <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
                <label className="app-logo-text">旺角廣東道1094-1096號快富閣1樓全層</label>
                <label className="app-logo-text">1/F, Fife Court, 1094-1096 Canton Road, Mongkok, HongKong</label>
                <label className="app-logo-text"><a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp: 96171444</a></label>
              </div>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-topic">
                  <p>選擇套票</p>
                  <p>Choose Package</p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <SafeAreaView style={styles.container}>
                  <ScrollView style={styles.scrollView} vertical={true}>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                      <DataRecordsBlock packages={packages} />
                    </Grid>
                  </ScrollView>
                </SafeAreaView>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-bottom">
                  <div className="app-bottom">
                    <div className="app-bottom-container">
                      <button type="submit" className="app-bottom-container-back" onClick={event =>  window.location.href=("/home/account/bookings")}>返回 Back</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
function DataRecordsBlock(props){
  const display = (props) => {
      const { packages } = props;
      console.log(packages);
      if(packages !== undefined){
        return(
          <>
            {
              packages.map( (item, i) => <RecordItem key={i} item={item} /> )
            }
          </>
        );
      }else{
        return(<h3></h3>);
      }
    }
    return(
      <>
        {display(props)}
      </>
    );
}
function RecordItem(props)
{
  const handlePackageClick = () => {
    //--
    LocalStorageTTL.setWithExpiry('selectedPackage', JSON.stringify(item));
    window.location.href=("/home/account/payment")
  }
  const { item } = props;
  return (
    <Grid item xs={12} sm={12} md={12} lg={6}>
      <Card className="app-package-block" onClick={handlePackageClick}>
        <CardActionArea>
          <CardContent>
            <Typography
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
              }}
              style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
              variant="body1"
            >
              <div className="app-package-text-block">
                <div className="app-package-text-block-left" style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}>
                  <div className="app-package-text-block-top">
                    <img className="img-tokenIcon" src={TokenIcon}/> x {item.token_num}
                  </div>
                  <div className="app-package-text-block-bottom">
                    限期 Expiration
                  </div>
                </div>
                <div className="app-package-text-block-right">
                  <div className="app-package-text-block-top">
                    {item.currency}${item.price} {item.isHot==1?"[熱門]":""}
                  </div>
                  <div className="app-package-text-block-bottom">
                    {item.expiry_day_period}日/Days
                  </div>
                </div>
              </div>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}
