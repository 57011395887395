import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Box,Paper, stackLayout,List, Grid } from '@mui/material';

import dayjs from 'dayjs';

import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import './BookingDetails.css';
import Logo from '../../logo.png';
import TokenIcon from '../../img/icon-DancingTicket.png';


export default function BookingDetails() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const [lesson, setLesson] = useState();
  const { class_id, date } = useParams();

  function countBookedNum(bookings,current_date){
    let count = 0;
    bookings.forEach(function (booking) {
      if(booking.status!="cancel"){
        if(booking.class_date.substring(0,10)==current_date){
          count++
        }
      }
    });
    return count
  }
  //--
  React.useEffect(() => {
    axios.get('', {
        params: {
          name: 'getLessonById',
          cid: class_id,
          headers: {
            'Authorization': 'Bearer ' + TokenService.getToken()
          },
        }
      })
    .then((response) => {
      console.log(response);
      if(response.status == 200){
        if(response.data.status == 200){
          console.log("getLessonById");
          console.log(response.data.class);
          console.log("-------------");
          setLesson(response.data.class);
        }
      }else{
        alert((i18n.language == 'zh_hk')?"錯誤":"错误");
      }
    });
  }, []);
  if(lesson){
    console.log("getSessionValue");
    //--Set day index
    var display_day = "Sun";
    if(lesson.day_index == 1){
      display_day = "Mon";
    }else if (lesson.day_index == 2) {
      display_day = "Tue";
    }else if (lesson.day_index == 3) {
      display_day = "Wed";
    }else if (lesson.day_index == 4) {
      display_day = "Thu";
    }else if (lesson.day_index == 5) {
      display_day = "Fri";
    }else if (lesson.day_index == 6) {
      display_day = "Sat";
    }
    const token = TokenService.getToken();
    const startToBook = () =>{
      if(!token){
        LocalStorageTTL.setWithExpiry('proceed_link', ('/home/book/'+lesson.cid)+ "/" + date +'/payment');
      }else{
        localStorage.removeItem('proceed_link');
      }
      window.location.href=('/home/book/'+lesson.cid)+ "/" + date +'/payment';
    }
    const remaining = lesson.capacity - countBookedNum(lesson.bookings, date);
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Row>
              <div className="app-logo">
                <img className="app-logo-img" src={Logo} onClick={event =>  window.location.href='/home'}/>
                <label className="app-logo-text">旺角廣東道1094-1096號快富閣1樓全層</label>
                <label className="app-logo-text">1/F, Fife Court, 1094-1096 Canton Road, Mongkok, HongKong</label>
                <label className="app-logo-text"><a className="app-logo-text-link" href="https://wa.me/+85296171444?text=Hello">Whatsapp: 96171444</a></label>
              </div>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-topic">
                  <p>課堂詳情</p>
                  <p>Booking Details</p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3'
                  }}
                >
                  <CardMedia
                    component="img"
                    src={lesson.image_url}
                    title={lesson.class_level}
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      width: "100%",
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      objectFit: 'unset',
                    }}
                  />
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3'
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                      variant="body1"
                    >
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {date} [{display_day}]
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {lesson.start_time.substring(0,5)} - {lesson.end_time.substring(0,5)}
                      </div>
                      <hr/>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {lesson.class_details}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {lesson.room_name}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        HKD${(lesson.token*150)} / <img className="img-tokenIcon" src={TokenIcon}/> 舞卷{lesson.token}張
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {remaining<1?"人數已滿":"剩"+remaining+"位"}
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
                <div className="app-bottom-container-booking">
                  {
                    dayjs().isBefore(dayjs(date+" "+lesson.end_time.substring(0,5)))?
                        <>
                        <button type="submit" className="app-bottom-container-booking-payment" onClick={startToBook}>預約 Booking</button>
                        </>
                     :
                        <>
                        </>
                  }
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
